// App.js
import React, { useState, useEffect } from "react";
import ChatInterface from "./components/chat/ChatInterface";
import Login from "./pages/Login";
import Register from "./pages/Register";
import axios from "axios";
import Profile from "./pages/Profile";
import Layout from "./components/common/Layout";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import VerifyEmail from './pages/VerifyEmail'; // インポートを追加
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

// 追加: Cookiesをインポート
import Cookies from "js-cookie";

// axios の baseURL を設定
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.withCredentials = true;

function App() {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("authToken") || ""
  );
  // isAuthenticatedの初期値をnullに設定
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [email, setEmail] = useState(""); // emailを追加

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "system");

  useEffect(() => {
    const root = window.document.documentElement;
    if (theme === "dark") {
      root.classList.add("dark");
    } else if (theme === "light") {
      root.classList.remove("dark");
    } else {
      // システムの設定に従う
      const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      if (mediaQuery.matches) {
        root.classList.add("dark");
      } else {
        root.classList.remove("dark");
      }
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  // handleLogoutをuseEffectよりも上に移動
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    setAuthToken("");
    setIsAuthenticated(false);
    axios.defaults.headers.common["Authorization"] = "";
    // クッキーからJWTトークンを削除
    Cookies.remove("jwt");
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const currentPath = window.location.pathname;
    if (token && currentPath === "/chat") {
      localStorage.setItem("authToken", token);
      setAuthToken(token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      // URLからトークンを削除
      const newURL = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, newURL);
    }

    if (authToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
      axios
        .get("/user")
        .then((response) => {
          setEmail(response.data.email);
          setIsAuthenticated(true);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          handleLogout();
        });
    } else {
      setIsAuthenticated(false);
    }
  }, [authToken]);

  // 認証状態がnullの間はローディング画面を表示
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      {isAuthenticated ? (
        <Routes>
          <Route
            path="/"
            element={
              <Layout
                handleLogout={handleLogout}
                email={email}
                theme={theme}
                setTheme={setTheme}
              />
            }
          >
            <Route path="chat" element={<ChatInterface />} />
            <Route path="chat/:chatId" element={<ChatInterface />} />{" "}
            {/* 追加 */}
            <Route
              path="profile"
              element={
                <Profile
                  handleLogout={handleLogout}
                  email={email}
                  theme={theme}
                  setTheme={setTheme}
                />
              }
            />
            <Route path="*" element={<Navigate to="/chat" replace />} />
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/login"
            element={<Login setAuthToken={setAuthToken} />}
          />
          <Route path="/register" element={<Register />} />
          <Route path="/verify-email" element={<VerifyEmail />} /> {/* 追加 */}
          <Route path="*" element={<Navigate to="/login" replace />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
