// Layout.js
import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import SideMenu from "./SideMenu";
import Header from "./Header";
import useChat from "../../hooks/useChat";

function Layout({ handleLogout, email, theme, setTheme }) {
  // ウィンドウ幅を取得
  const initialWindowWidth = window.innerWidth;
  // 初期値を画面幅に応じて設定
  const [isSidebarOpen, setIsSidebarOpen] = useState(initialWindowWidth >= 768);
  const [profileImageUrl, setProfileImageUrl] = useState(null);

  const chatContext = useChat();

  const {
    messages,
    pastChats,
    activeChatId,
    currentChatTitle,
    startNewChat,
    deleteChat,
    renameChat,
  } = chatContext;

  const navigate = useNavigate();

  // ウィンドウサイズの変更を監視
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // ユーザープロフィールの取得
  useEffect(() => {
    axios
      .get("user/profile")
      .then((res) => {
        setTheme(res.data.theme || "system");
        if (res.data.profileImageUrl) {
          setProfileImageUrl(res.data.profileImageUrl);
        }
      })
      .catch((err) => {
        console.error("Error fetching user profile:", err);
      });
  }, [setTheme]);

  return (
    <div className="h-screen text-sm overflow-hidden flex bg-background dark:bg-background-dark">
      {/* サイドメニュー */}
      <SideMenu
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        handleLogout={handleLogout}
        email={email}
        theme={theme}
        setTheme={setTheme}
        profileImageUrl={profileImageUrl}
        pastChats={pastChats}
        activeChatId={activeChatId}
        onChatSelect={(chatId) => {
          navigate(`/chat/${chatId}`);
        }}
        onChatDelete={deleteChat}
        onChatRename={renameChat}
        startNewChat={startNewChat}
        messages={messages}
      />
      {/* メインコンテンツ */}
      <div
        className={`flex-1 flex flex-col min-w-0 transition-all duration-300 ${
          isSidebarOpen ? "md:ml-64" : "ml-0"
        }`}
        style={{ zIndex: 0 }}
        onClick={() => {
          if (isSidebarOpen && window.innerWidth < 768) {
            setIsSidebarOpen(false);
          }
        }}
      >
        {/* ヘッダー */}
        <Header
          currentChatTitle={currentChatTitle}
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
        />
        {/* 子コンポーネントにコンテキストを渡す */}
        <div className="flex-grow overflow-auto bg-background dark:bg-background-dark">
          <Outlet context={chatContext} />
        </div>
      </div>
    </div>
  );
}

export default Layout;
