// EditMessageForm.js
import React, { useState, useLayoutEffect, useRef } from "react";
import {
  XCircleIcon,
  XMarkIcon,
  PaperClipIcon,
  ArrowUpIcon,
} from "@heroicons/react/24/solid";
import axios from "axios";
import ImageWithPopup from "./ImageWithPopup";
import modelIcons from "../../assets/modelIcons"; // パスはassetsフォルダに合わせて調整
const API_URL =
  process.env.REACT_APP_API_URL;
const BASE_URL = API_URL.replace("/api", "");

function EditMessageForm({
  currentMessage,
  setEditingId,
  updateMessage,
  setError,
  isResponding,
  selectedModel,
  setSelectedModel,
}) {
  const [localEditInput, setLocalEditInput] = useState(
    currentMessage.content || ""
  );
  const [localEditSelectedFiles, setLocalEditSelectedFiles] = useState(
    currentMessage.files || []
  );
  const [localSelectedModel, setLocalSelectedModel] = useState(
    currentMessage.model || selectedModel || "claude-3-5-sonnet-20240620"
  );
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  const [isUploading, setIsUploading] = useState(false); // 追加

  const textareaRef = useRef(null);

  // useLayoutEffect を使用して高さを調整
  useLayoutEffect(() => {
    adjustTextareaHeight();
  }, [localEditInput]);

  useLayoutEffect(() => {
    adjustTextareaHeight();
  }, []);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleLocalEditInputChange = (e) => {
    setLocalEditInput(e.target.value);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter((prev) => prev + 1);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDraggingOver(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter((prev) => prev - 1);
    if (dragCounter === 1) {
      setIsDraggingOver(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(0);
    setIsDraggingOver(false);
    setIsUploading(true); // 追加

    const files = Array.from(e.dataTransfer.files);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setLocalEditSelectedFiles((prev) => [...prev, ...response.data.files]);
    } catch (error) {
      console.error("Error uploading files:", error);
      setError(
        "ファイルのアップロードに失敗しました。もう一度お試しください。"
      );
    } finally {
      setIsUploading(false); // 追加
    }
  };
  const handleLocalEditFileUpload = async (event) => {
    setIsUploading(true); // 追加
    const files = Array.from(event.target.files);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setLocalEditSelectedFiles((prev) => [...prev, ...response.data.files]);
    } catch (error) {
      console.error("Error uploading files:", error);
      setError(
        "ファイルのアップロードに失敗しました。もう一度お試しください。"
      );
    } finally {
      setIsUploading(false); // 追加
    }
  };

  const removeLocalEditFile = (index) => {
    setLocalEditSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const expandableDivStyle = {
    overflow: "hidden",
    transition: "max-height 0.3s ease-in-out, padding 0.3s ease-in-out",
  };

  const modelOptions = [
    { value: "claude-3-5-sonnet-20240620", label: "Claude 3.5" },
    { value: "gpt-4o-2024-08-06", label: "GPT-4o" },
    { value: "gemini-1.5-pro", label: "Gemini 1.5 Pro" },
    { value: "o1-preview", label: "OpenAI o1-preview" }, // 追加
    {
      value: "llama-3.1-sonar-huge-128k-online",
      label: "Perplexity (Llama-3.1)",
    },
    { value: "stable-image-ultra", label: "Stable Image Ultra" },
    { value: "dall-e-3", label: "DALL-E 3" },
  ];

  // 音声ファイルの拡張子を定義
  const audioExtensions = [
    ".mp3",
    ".mp4",
    ".mpeg",
    ".mpga",
    ".m4a",
    ".wav",
    ".webm",
  ];

  // 音声ファイルかどうかを判定する関数
  const isAudioFile = (file) => {
    return audioExtensions.some((ext) => file.filename.endsWith(ext));
  };

  // ファイル拡張子を取得する関数
  const getFileExtension = (file) => {
    return file.filename.split(".").pop().toUpperCase();
  };

  const handleKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === "ArrowDown") {
      e.preventDefault();
      if (textareaRef.current) {
        const textarea = textareaRef.current;
        textarea.selectionStart = textarea.value.length;
        textarea.selectionEnd = textarea.value.length;
        textarea.scrollTop = textarea.scrollHeight;
      }
    }
  };

  return (
    <div
      className="flex flex-col w-full"
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
      style={{ overflowAnchor: "none" }}
    >
      {/* テキストエリア */}
      <textarea
        ref={textareaRef}
        id={`edit-textarea-${currentMessage._id}`}
        className="flex-grow p-2 rounded resize-none overflow-y-auto mb-2 w-full bg-[#f7f7f7] text-textPrimary focus:outline-none dark:bg-[#2f2f2f] dark:text-textPrimary-dark"
        onChange={handleLocalEditInputChange}
        style={{
          height: "0",
          overflow: "hidden",
          overflowAnchor: "none",
        }}
        value={localEditInput}
        onKeyDown={handleKeyDown} // 追加
        placeholder="メッセージを入力...(Shift + Enterで改行)"
      />
      {/* アイコン群 */}
      <div className="flex justify-between items-center">
        {/* 左側のアイコン */}
        <div className="flex items-center">
          {/* モデル選択アイコン */}
          <div className="flex space-x-1 ml-2 overflow-x-auto flex-nowrap">
            {modelOptions.map((model) => (
              <button
                key={model.value}
                onClick={() => setLocalSelectedModel(model.value)}
                title={model.label}
                className={`rounded-xl border p-2 flex-shrink-0 flex items-center justify-center transition-all duration-200
                ${
                  localSelectedModel === model.value
                    ? "border-modelButtonBorder-light dark:border-modelButtonBorder-dark bg-modelButtonSelected-light dark:bg-modelButtonSelected-dark"
                    : "border-transparent"
                }
                hover:bg-modelButtonHover-light dark:hover:bg-modelButtonHover-dark`}
              >
                <img
                  src={modelIcons[model.value]}
                  alt={model.label}
                  className="h-6 w-6 object-contain"
                />
              </button>
            ))}
          </div>
        </div>
        {/* 右側のアイコン */}
        <div className="flex items-center mt-2">
          {/* ファイル（クリップ）アイコン */}
          <label className="flex items-center justify-center cursor-pointer p-2 mr-2">
            <input
              type="file"
              accept="image/*,.pdf,.csv,audio/*"
              onChange={handleLocalEditFileUpload}
              multiple
              className="hidden"
            />
            <PaperClipIcon className="h-5 w-5 text-gray-700 dark:text-gray-300" />
          </label>
          {/* キャンセルボタン */}
          <button
            className="flex items-center justify-center p-2 mr-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200"
            onClick={() => {
              setEditingId(null);
            }}
          >
            <XMarkIcon className="h-5 w-5 text-gray-700 dark:text-gray-300" />
          </button>
          {/* 送信ボタン */}
          <button
            className={`flex items-center justify-center p-2 mr-2 rounded-full transition-colors duration-200 ${
              localEditInput.trim()
                ? "bg-sendButtonActiveLight text-white dark:bg-sendButtonActiveDark dark:text-black"
                : "bg-sendButtonInactiveLight text-black dark:bg-sendButtonInactiveDark dark:text-white cursor-not-allowed"
            }`}
            onClick={async () => {
              await updateMessage(
                currentMessage._id,
                localEditInput,
                localEditSelectedFiles,
                localSelectedModel
              );
              setEditingId(null);
            }}
            disabled={!localEditInput.trim()}
          >
            <ArrowUpIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
      {/* ドラッグ＆ドロップエリア */}
      <div
        style={{
          ...expandableDivStyle,
          maxHeight:
            isDraggingOver || isUploading || localEditSelectedFiles.length > 0
              ? "150px"
              : "0px",
          padding:
            isDraggingOver || isUploading || localEditSelectedFiles.length > 0
              ? "0.5rem"
              : "0rem",
        }}
      >
        <div className="flex flex-col p-2 border-2 border-dashed rounded-lg">
          {/* ファイル表示エリア */}
          {isUploading ? (
            <p className="text-blue-600 font-semibold">
              ファイルをアップロードしています...
            </p>
          ) : localEditSelectedFiles.length > 0 ? (
            <div className="flex flex-wrap">
              {localEditSelectedFiles.map((file, index) => (
                <div key={index} className="relative m-1">
                  {file.mimetype === "application/pdf" ? (
                    <div className="w-20 h-20 bg-red-100 flex items-center justify-center rounded">
                      <span className="text-red-500 font-bold">PDF</span>
                    </div>
                  ) : file.mimetype === "text/csv" ? (
                    <div className="w-20 h-20 bg-green-100 flex items-center justify-center rounded">
                      <span className="text-green-500 font-bold">CSV</span>
                    </div>
                  ) : isAudioFile(file) ? (
                    <div className="w-20 h-20 bg-blue-100 flex items-center justify-center rounded">
                      <span className="text-blue-500 font-bold">
                        {getFileExtension(file)}
                      </span>
                    </div>
                  ) : (
                    <ImageWithPopup
                      src={`${BASE_URL}${file.filepath}`}
                      alt={`Uploaded ${index}`}
                    />
                  )}
                  <button
                    onClick={() => removeLocalEditFile(index)}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors duration-200"
                  >
                    <XCircleIcon className="h-4 w-4" />
                  </button>
                </div>
              ))}
            </div>
          ) : isDraggingOver ? (
            <p className="text-blue-600 font-semibold">
              + ドロップしてファイルを追加
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default EditMessageForm;
