// ChatTree.js
import React, { useState, useEffect, Fragment, useRef } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { Dialog, Transition } from "@headlessui/react";

function ChatTree({
  pastChats,
  activeChatId,
  onChatSelect,
  onChatDelete,
  onChatRename,
}) {
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [newChatTitle, setNewChatTitle] = useState("");
  const [openMenuChatId, setOpenMenuChatId] = useState(null);

  const menuRefs = useRef({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        openMenuChatId &&
        menuRefs.current[openMenuChatId] &&
        !menuRefs.current[openMenuChatId].contains(event.target)
      ) {
        setOpenMenuChatId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenuChatId]);

  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    if (isNaN(date)) return null;
    return date.toLocaleString();
  };

  return (
    <div className="p-2 bg-sidebarBgLight dark:bg-sidebarBackground-dark">
      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2 text-textPrimary dark:text-textPrimary-dark">
          過去のチャット
        </h2>
        {pastChats.map((chat) => (
          <Transition
            key={chat.id}
            as={Fragment}
            show={true}
            enter="transition-transform duration-300"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition-transform duration-200"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <div
              className={`flex justify-between items-center py-2 px-2 rounded-lg cursor-pointer ${
                chat.id === activeChatId
                  ? "bg-[#ececec] text-black dark:bg-[#212121] dark:text-white"
                  : "hover:bg-[#ececec] dark:hover:bg-[#212121] text-textPrimary dark:text-textPrimary-dark"
              } group`}
              onClick={() => onChatSelect(chat.id)}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Delete") {
                  e.stopPropagation();
                  setSelectedChatId(chat.id);
                  setIsDeleteModalOpen(true);
                  setOpenMenuChatId(null);
                }
              }}
            >
              <div className="flex-grow overflow-hidden">
                <div className="truncate">{chat.title}</div>
                {formatDate(chat.lastActivityAt) && (
                  <div className="text-xs text-gray-500">
                    最終更新: {formatDate(chat.lastActivityAt)}
                  </div>
                )}
              </div>
              <div
                className="relative"
                ref={(el) => (menuRefs.current[chat.id] = el)}
              >
                <button
                  title="オプション"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (openMenuChatId === chat.id) {
                      setOpenMenuChatId(null);
                    } else {
                      setOpenMenuChatId(chat.id);
                    }
                  }}
                  className={`text-gray-500 hover:text-gray-700 ml-2 ${
                    chat.id === activeChatId
                      ? "opacity-100"
                      : "opacity-0 group-hover:opacity-100"
                  } transition-opacity duration-200`}
                >
                  <EllipsisVerticalIcon className="h-5 w-5" />
                </button>
                {/* メニュー */}
                <Transition
                  as={Fragment}
                  show={openMenuChatId === chat.id}
                  enter="transition-opacity duration-200"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className="absolute right-0 mt-2 w-32 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded shadow-lg z-50"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <button
                      className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700 text-textPrimary dark:text-textPrimary-dark"
                      onClick={() => {
                        setSelectedChatId(chat.id);
                        setIsRenameModalOpen(true);
                        setOpenMenuChatId(null);
                      }}
                    >
                      名前の変更
                    </button>
                    <button
                      className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-700 text-error"
                      onClick={() => {
                        setSelectedChatId(chat.id);
                        setIsDeleteModalOpen(true);
                        setOpenMenuChatId(null);
                      }}
                    >
                      削除
                    </button>
                  </div>
                </Transition>
              </div>
            </div>
          </Transition>
        ))}
      </div>
      {/* 削除モーダル */}
      <Transition appear show={isDeleteModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsDeleteModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-gray-900"
                  >
                    チャットの削除
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      本当にこのチャットを削除しますか？この操作は取り消せません。
                    </p>
                  </div>

                  <div className="mt-4 flex justify-end">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-transparent rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 mr-2"
                      onClick={() => setIsDeleteModalOpen(false)}
                    >
                      キャンセル
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                      onClick={() => {
                        onChatDelete(selectedChatId);
                        setIsDeleteModalOpen(false);
                      }}
                    >
                      削除
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* リネームモーダル */}
      <Transition appear show={isRenameModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsRenameModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-gray-900"
                  >
                    チャットの名前を変更
                  </Dialog.Title>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      placeholder="新しいチャットのタイトルを入力してください"
                      value={newChatTitle}
                      onChange={(e) => setNewChatTitle(e.target.value)}
                    />
                  </div>

                  <div className="mt-4 flex justify-end">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-transparent rounded-md hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 mr-2"
                      onClick={() => {
                        setIsRenameModalOpen(false);
                        setNewChatTitle("");
                      }}
                    >
                      キャンセル
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={() => {
                        onChatRename(selectedChatId, newChatTitle.trim());
                        setIsRenameModalOpen(false);
                        setNewChatTitle("");
                      }}
                    >
                      変更
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default ChatTree;
