// src/pages/Register.js

import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

function Register() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // エラーメッセージと情報メッセージを管理
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      // エラーメッセージをリセット
      setError("");
      setMessage("");

      // サーバーにユーザー登録リクエストを送信
      const response = await axios.post(
        "/register",
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ); 

      setMessage(response.data.message);

      // 登録成功時に認証コード入力ページにリダイレクト
      setTimeout(() => {
        navigate("/verify-email", { state: { email } });
      }, 2000);
    } catch (error) {
      console.error("Error registering:", error);
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError("ユーザー登録に失敗しました。もう一度お試しください。");
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800 dark:text-white">
          新規登録
        </h2>
        {message && (
          <p className="text-green-500 mb-4 text-center font-semibold">
            {message}
          </p>
        )}
        {error && (
          <p className="text-red-500 mb-4 text-center font-semibold">{error}</p>
        )}
        <form onSubmit={handleRegister}>
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              メールアドレス
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white"
              placeholder="メールアドレスを入力"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              パスワード
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white"
              placeholder="パスワードを入力"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary-dark transition-colors duration-200"
          >
            登録
          </button>
        </form>
        <p className="mt-6 text-center text-gray-600 dark:text-gray-400">
          既にアカウントをお持ちですか？{" "}
          <Link to="/login" className="text-primary hover:underline">
            ログインはこちら
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Register;
