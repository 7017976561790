// streamProcessor.js
export async function processMessageStream(
  response,
  updateStateCallback,
  initialMessageData,
  setMessages,
  setActiveMessageId,
  setActiveMessageIds,
  tempId,
  setError,
  onComplete,
  setMessageVersionState,
  onNewMessageId // 追加
) {
  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  let newMessageId = null;
  let aiResponse = "";
  let lastUpdateTime = Date.now();

  const updateState = () => {
    const messageIdToUpdate = newMessageId || tempId;
    updateStateCallback(messageIdToUpdate, aiResponse);
  };

  let isFirstChunk = true;
  let buffer = "";

  while (true) {
    const { value, done } = await reader.read();
    if (done) break;
    const chunk = decoder.decode(value);
    buffer += chunk;

    let lines = buffer.split("\n\n");
    buffer = lines.pop();

    for (const line of lines) {
      if (line.startsWith("data: ")) {
        const dataStr = line.slice(6).trim();
        if (dataStr === "[DONE]") {
          updateState();
          break;
        } else {
          try {
            const data = JSON.parse(dataStr);
            if (data.messageId) {
              newMessageId = data.messageId;
              const fixedNewMessageId = newMessageId;

              // メッセージIDの更新
              setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                  msg._id === tempId ? { ...msg, _id: fixedNewMessageId } : msg
                )
              );

              // activeMessageIdとactiveMessageIdsの更新
              setActiveMessageId((prevId) =>
                prevId === tempId ? fixedNewMessageId : prevId
              );
              setActiveMessageIds((prevIds) =>
                prevIds.map((id) => (id === tempId ? fixedNewMessageId : id))
              );

              // messageVersionStateの更新
              setMessageVersionState((prevState) => ({
                ...prevState,
                [initialMessageData._id]: fixedNewMessageId,
              }));

              // 新しいコールバックの呼び出し
              if (onNewMessageId) {
                onNewMessageId(tempId, fixedNewMessageId);
              }
            } else if (data.content) {
              if (isFirstChunk) {
                const idToUpdate = newMessageId || tempId;
                isFirstChunk = false;
                setMessages((prevMessages) =>
                  prevMessages.map((msg) =>
                    msg._id === idToUpdate
                      ? { ...msg, notStartingAIResponseYet: false }
                      : msg
                  )
                );
              }
              aiResponse += data.content;
              const now = Date.now();
              if (now - lastUpdateTime > 100) {
                lastUpdateTime = now;
                updateState();
              }
            } else if (data.error) {
              setError(data.error);
              break;
            }
          } catch (error) {
            console.error("Error parsing stream data:", error);
          }
        }
      }
    }
  }

  if (buffer.length > 0) {
    const lines = buffer.split("\n\n");
    for (const line of lines) {
      if (line.startsWith("data: ")) {
        const dataStr = line.slice(6).trim();
        if (dataStr === "[DONE]") {
          updateState();
          break;
        } else {
          console.error("Error parsing stream data:");
        }
      }
    }
  }

  // 最後の更新
  setMessages((prevMessages) =>
    prevMessages.map((msg) =>
      msg._id === (newMessageId || tempId)
        ? { ...msg, notStartingAIResponseYet: false, isResponseComplete: true }
        : msg
    )
  );

  updateState();

  if (onComplete) {
    onComplete();
  }
}
