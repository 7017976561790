import React, { useState, useCallback, useEffect, useRef } from "react";
import { ClipboardIcon } from "@heroicons/react/24/solid";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  vscDarkPlus,
} from "react-syntax-highlighter/dist/esm/styles/prism";
const CodeBlock = React.memo(({ language, value, fixingCopyButton }) => {
  const copiedRef = useRef(false);
  const [copied, setCopiedState] = useState(false);
  const codeBlockRef = useRef(null);
  const copyButtonRef = useRef(null);

  const setCopied = (state) => {
    copiedRef.current = state;
    setCopiedState(state);
  };

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(value).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  }, [value]);

  useEffect(() => {
    setCopied(copiedRef.current);
  }, [value]);

  useEffect(() => {
    if (fixingCopyButton) {
      if (copyButtonRef.current) {
        copyButtonRef.current.style.position = "absolute";
        copyButtonRef.current.style.top = "5px";
        copyButtonRef.current.style.right = "10px";
      }
    } else {
      // AIの返答後はコピーボタンをスクロールに追従
      const handleScroll = () => {
        if (!codeBlockRef.current || !copyButtonRef.current) return;

        const codeBlockRect = codeBlockRef.current.getBoundingClientRect();
        const copyButton = copyButtonRef.current;
        const container = document.querySelector(
          ".flex-grow.overflow-auto.p-2"
        );
        const containerRect = container.getBoundingClientRect();

        const codeBlockTop = codeBlockRect.top;
        const codeBlockBottom = codeBlockRect.bottom;
        const containerTop = containerRect.top;
        const containerBottom = containerRect.bottom;

        const codeBlockHeight = codeBlockRect.height;
        const copyButtonHeight = copyButton.offsetHeight;

        if (
          codeBlockBottom <= containerTop ||
          codeBlockTop >= containerBottom
        ) {
          copyButton.style.display = "none";
        } else {
          copyButton.style.display = "block";

          let topOffset = containerTop - codeBlockTop + 10;
          if (topOffset < 10) topOffset = 5;

          const maxTop = codeBlockHeight - copyButtonHeight - 10;
          if (topOffset > maxTop) topOffset = maxTop;

          copyButton.style.position = "absolute";
          copyButton.style.top = `${topOffset}px`;
          copyButton.style.right = "10px";

          // 背景色と角の丸みを維持
          const headerStyle = getComputedStyle(copyButton.parentElement);
          copyButton.style.background = headerStyle.backgroundColor;
          // 角を丸くする。headerStyleは使わない。
          copyButton.style.borderRadius = "0.25rem";
          // paddingを追加
          copyButton.style.padding = "0.1rem 0.2rem";
        }
      };

      const container = document.querySelector(".flex-grow.overflow-auto.p-2");
      container.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleScroll);

      handleScroll();

      return () => {
        container.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleScroll);
      };
    }
  }, [fixingCopyButton]);

  return (
    <div
      className="code-block-container overflow-hidden max-w-full w-full rounded-lg not-prose"
      ref={codeBlockRef}
      style={{ position: "relative", overflow: "hidden" }}
    >
      {/* ヘッダー部分 */}
      <div className="flex justify-between items-center bg-[#2f2f2f] px-4 py-2 rounded-t-lg">
        <span className="text-sm font-medium text-gray-100">{language}</span>
        <button
          ref={copyButtonRef}
          className="copy-button text-gray-100 flex items-center"
          onClick={copyToClipboard}
          style={{
            position: "sticky",
            top: "10px",
            right: "10px",
            alignSelf: "flex-start",
            marginLeft: "auto",
          }}
        >
          {copied ? (
            "Copied!"
          ) : (
            <>
              <ClipboardIcon className="h-4 w-4 inline mr-1" />
              Copy
            </>
          )}
        </button>
      </div>
      {/* コードブロック部分 */}
      <SyntaxHighlighter
        style={vscDarkPlus}
        language={language}
        PreTag="div"
        codeTagProps={{
          style: {
            fontSize: "1.25em",
            fontWeight: "400",
            lineHeight: "1.7142857",
          },
        }}
        customStyle={{
          //backgroundColor: "#0d0d0d", // 背景色を追加
          margin: 0,
          padding: "1em",
          overflowX: "auto",
          whiteSpace: "pre",
          wordBreak: "normal",
          maxWidth: "100%",
          borderRadius: "0 0 0.5rem 0.5rem",
        }}
      >
        {value}
      </SyntaxHighlighter>
    </div>
  );
});

export default CodeBlock;
