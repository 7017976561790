// src/pages/ResetPassword.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get("resetToken");
    if (tokenParam) {
      setToken(tokenParam);
    } else {
      setError("無効なパスワードリセットトークンです。");
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setMessage("");
      const response = await axios.post("/reset-password", {
        token,
        newPassword,
      });
      setMessage(response.data.message);

      // パスワードリセット成功後、ログインページにリダイレクト
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      console.error("Error resetting password:", error);
      setError(
        error.response?.data?.error || "パスワードのリセットに失敗しました。"
      );
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800 dark:text-white">
          パスワードのリセット
        </h2>
        {message && (
          <p className="text-green-500 mb-4 text-center font-semibold">
            {message}
          </p>
        )}
        {error && (
          <p className="text-red-500 mb-4 text-center font-semibold">{error}</p>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              新しいパスワード
            </label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white"
              placeholder="新しいパスワードを入力"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary-dark transition-colors duration-200"
          >
            パスワードをリセット
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
