// VerifyEmail.js

import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

function VerifyEmail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const email = location.state?.email || "";

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setMessage("");

      const response = await axios.post("/verify-email", {
        email,
        verificationCode,
      });

      setMessage(response.data.message);

      // 認証が成功したら、ログインページにリダイレクト
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      console.error("Error verifying email:", error);
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError("メール認証に失敗しました。もう一度お試しください。");
      }
    }
  };

  const handleResendCode = async () => {
    try {
      setError("");
      setMessage("");

      const response = await axios.post("/resend-verification-code", {
        email,
      });

      setMessage(response.data.message);
    } catch (error) {
      console.error("Error resending verification code:", error);
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError("認証コードの再送信に失敗しました。もう一度お試しください。");
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800 dark:text-white">
          メールアドレスの確認
        </h2>
        {message && (
          <p className="text-green-500 mb-4 text-center font-semibold">
            {message}
          </p>
        )}
        {error && (
          <p className="text-red-500 mb-4 text-center font-semibold">{error}</p>
        )}
        <form onSubmit={handleVerify}>
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              認証コード
            </label>
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white"
              placeholder="メールで受け取った認証コードを入力"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary-dark transition-colors duration-200"
          >
            認証
          </button>
        </form>
      </div>
      <div className="text-center mt-4">
        <p className="text-gray-600 dark:text-gray-400">
          認証コードを受け取っていない場合は
        </p>
        <button
          type="button"
          onClick={handleResendCode}
          className="text-primary hover:underline mt-2"
        >
          認証コードを再送信
        </button>
      </div>
    </div>
  );
}

export default VerifyEmail;
