// useChat.js
import { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { processMessageStream } from "../utils/streamProcessor";
import { useParams, useNavigate } from "react-router-dom"; // 修正
const API_URL =
  process.env.REACT_APP_API_URL;

function useChat() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState(() => localStorage.getItem("input") || "");
  const [editingId, setEditingId] = useState(null);
  const [error, setError] = useState(null);
  const [isResponding, setIsResponding] = useState(false);
  const [notStartingAIResponseYet, setNotStartingAIResponseYet] =
    useState(false);
  const [activeMessageId, setActiveMessageId] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [pastChats, setPastChats] = useState([]);
  const [activeChatId, setActiveChatId] = useState(null);
  const [currentChatTitle, setCurrentChatTitle] = useState("");
  const [selectedModel, setSelectedModel] = useState(() => {
    return (
      localStorage.getItem("selectedModel") || "claude-3-5-sonnet-20240620"
    );
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [messageVersionState, setMessageVersionState] = useState({});
  const [activeMessageIds, setActiveMessageIds] = useState([]);
  const { chatId } = useParams();
  const navigate = useNavigate(); // 追加
  const isNewChat = currentChatTitle === "New Chat" && messages.length === 0;

  const abortControllerRef = useRef({});
  const currentRequestIdRef = useRef("");
  const [isUploading, setIsUploading] = useState(false); // 追加
  useEffect(() => {
    localStorage.setItem("selectedModel", selectedModel);
  }, [selectedModel]);

  useEffect(() => {
    localStorage.setItem("input", input);
  }, [input]);

  useEffect(() => {
    if (activeChatId && pastChats.length > 0) {
      const chat = pastChats.find((chat) => chat.id === activeChatId);
      setCurrentChatTitle(chat ? chat.title : "");
    }
  }, [activeChatId, pastChats]);

  useEffect(() => {
    if (chatId) {
      setActiveChatId(chatId);
      loadChat(chatId);
    }
  }, [chatId]);

  const startNewChat = useCallback(async () => {
    try {
      const newChat = await axios.post(`${API_URL}/chats`, {
        title: "New Chat",
      });
      setPastChats((prevChats) => [
        { id: newChat.data.chatId, title: newChat.data.title },
        ...prevChats,
      ]);
      setActiveChatId(newChat.data.chatId);
      navigate(`/chat/${newChat.data.chatId}`);
      setCurrentChatTitle(newChat.data.title);
      setMessages([]);
      setActiveMessageId(null);
      setInput("");
      setSelectedFiles([]);
      localStorage.setItem("lastActiveChatId", newChat.data.chatId);
      setMessageVersionState({});
    } catch (error) {
      console.error("Error starting new chat:", error);
      setError("新しいチャットの開始に失敗しました。もう一度お試しください。");
    }
  }, [navigate]);

  // 初回実行を制御するためのフラグを追加
  const hasFetchedChats = useRef(false);

  useEffect(() => {
    if (hasFetchedChats.current) return;
    hasFetchedChats.current = true;

    const fetchChats = async () => {
      try {
        const response = await axios.get(`${API_URL}/chats`);
        const sortedChats = response.data.sort(
          (a, b) => new Date(b.lastActivityAt) - new Date(a.lastActivityAt)
        );
        setPastChats(sortedChats);

        if (chatId && sortedChats.some((chat) => chat.id === chatId)) {
          setActiveChatId(chatId);
          loadChat(chatId);
        } else {
          const lastActiveChatId = localStorage.getItem("lastActiveChatId");
          if (
            lastActiveChatId &&
            sortedChats.some((chat) => chat.id === lastActiveChatId)
          ) {
            setActiveChatId(lastActiveChatId);
            const lastActiveMessageId = localStorage.getItem(
              `lastActiveMessageId_${lastActiveChatId}`
            );
            const savedMessageVersionState = localStorage.getItem(
              `messageVersionState_${lastActiveChatId}`
            );
            if (savedMessageVersionState) {
              setMessageVersionState(JSON.parse(savedMessageVersionState));
            }
            loadChat(lastActiveChatId, lastActiveMessageId);
          } else if (sortedChats.length > 0) {
            setActiveChatId(sortedChats[0].id);
            loadChat(sortedChats[0].id);
          } else {
            // チャットが存在しない場合、新しいチャットを作成
            if (currentChatTitle !== "New Chat" || messages.length !== 0) {
              await startNewChat();
            }
          }
        }
      } catch (error) {
        console.error("Error fetching chats:", error);
        setError("チャットの取得に失敗しました。もう一度お試しください。");
      }
    };
    fetchChats();
  }, [chatId, startNewChat, currentChatTitle, messages.length]);

  useEffect(() => {
    if (activeChatId) {
      const savedMessageVersionState = localStorage.getItem(
        `messageVersionState_${activeChatId}`
      );
      if (savedMessageVersionState) {
        setMessageVersionState(JSON.parse(savedMessageVersionState));
      } else {
        setMessageVersionState({});
      }
    }
  }, [activeChatId]);

  useEffect(() => {
    if (activeMessageId && activeChatId) {
      localStorage.setItem(
        `lastActiveMessageId_${activeChatId}`,
        activeMessageId
      );
    }
  }, [activeMessageId, activeChatId]);

  useEffect(() => {
    if (messageVersionState && activeChatId) {
      localStorage.setItem(
        `messageVersionState_${activeChatId}`,
        JSON.stringify(messageVersionState)
      );
    }
  }, [messageVersionState, activeChatId]);

  const generateTitle = useCallback(
    async (content) => {
      try {
        const response = await axios.post(`${API_URL}/generate-title`, {
          content,
          model: selectedModel,
        });
        return response.data.title;
      } catch (error) {
        console.error("Error generating title:", error);
        return "New Chat";
      }
    },
    [selectedModel]
  );

  const renameChat = async (chatId, newTitle) => {
    if (newTitle && newTitle.trim() !== "") {
      try {
        await axios.put(`${API_URL}/chats/${chatId}/title`, {
          title: newTitle.trim(),
        });
        setPastChats((prevChats) =>
          prevChats.map((chat) =>
            chat.id === chatId ? { ...chat, title: newTitle.trim() } : chat
          )
        );
        if (chatId === activeChatId) {
          setCurrentChatTitle(newTitle.trim());
        }
      } catch (error) {
        console.error("Error renaming chat:", error);
        setError("チャットの名前変更に失敗しました。もう一度お試しください。");
      }
    }
  };

  const deleteChat = async (chatId) => {
    try {
      await axios.delete(`${API_URL}/chats/${chatId}`);
      setPastChats((prevChats) =>
        prevChats.filter((chat) => chat.id !== chatId)
      );

      if (chatId === activeChatId) {
        if (pastChats.length > 1) {
          const nextChat = pastChats.find((chat) => chat.id !== chatId);
          if (nextChat) {
            setTimeout(() => {
              loadChat(nextChat.id);
            }, 300);
          }
        } else {
          startNewChat();
        }
      }
    } catch (error) {
      console.error("Error deleting chat:", error);
      setError("Failed to delete chat. Please try again.");
    }
  };

  const loadChat = async (chatId, messageId = null) => {
    try {
      const response = await axios.get(`${API_URL}/chats/${chatId}/messages`);
      setMessages(response.data);
      setActiveChatId(chatId);

      if (response.data.length > 0) {
        const messageIds = response.data.map((m) => m._id);
        if (messageId && messageIds.includes(messageId)) {
          setActiveMessageId(messageId);
        } else {
          setActiveMessageId(response.data[response.data.length - 1]._id);
        }
      } else {
        setActiveMessageId(null);
      }
      localStorage.setItem("lastActiveChatId", chatId);

      const savedMessageVersionState = localStorage.getItem(
        `messageVersionState_${chatId}`
      );
      if (savedMessageVersionState) {
        setMessageVersionState(JSON.parse(savedMessageVersionState));
      } else {
        setMessageVersionState({});
      }
    } catch (error) {
      console.error("Error loading chat:", error);
      setError("Failed to load chat. Please try again.");
    }
  };

  const modelSupportsFiles = (model) => {
    if (model.toLowerCase().startsWith("o1")) {
      return false; // o1モデルはファイルをサポートしない
    }
    return (
      model.toLowerCase().startsWith("gpt") ||
      model.toLowerCase().startsWith("claude") ||
      model.toLowerCase().startsWith("gemini")
    );
  };

  const cancelResponse = useCallback(() => {
    const requestId = currentRequestIdRef.current;
    if (
      requestId &&
      abortControllerRef.current &&
      abortControllerRef.current[requestId]
    ) {
      abortControllerRef.current[requestId].abort();
      delete abortControllerRef.current[requestId];
      setIsResponding(false);
      setNotStartingAIResponseYet(false);
      // メッセージの notStartingAIResponseYet を false に更新
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg._id === activeMessageId
            ? { ...msg, notStartingAIResponseYet: false }
            : msg
        )
      );
    }
  }, [activeMessageId]); // 'messages'を依存関係に追加

  const sendMessage = useCallback(
    async (
      content,
      parentId = null,
      isRetry = false,
      files = [], // 修正
      retryMessageId = null,
      model = selectedModel
    ) => {
      if (isResponding) {
        cancelResponse();
      }

      // ファイルサポートのチェック
      if (files.length > 0 && !modelSupportsFiles(model)) {
        setError("このモデルではファイルを送信できません。");
        return;
      }

      setIsResponding(true);
      setNotStartingAIResponseYet(true);

      const tempId = Date.now().toString(); // 一意の仮IDを生成

      // ユーザーのメッセージを即座に追加
      const tempMessage = {
        _id: tempId,
        content,
        parentId,
        files, // 修正
        model: model,
        createdAt: Date.now(),
        aiResponse: "",
        notStartingAIResponseYet: true,
        isResponseComplete: false, // ここでリセット
      };

      setMessages((prevMessages) => [...prevMessages, tempMessage]);

      // activeMessageId と activeMessageIds を更新
      setActiveMessageId(tempId);
      setActiveMessageIds((prevIds) => [...prevIds, tempId]);

      const requestId = Date.now().toString(); // 一意のリクエストIDを生成
      currentRequestIdRef.current = requestId;
      const abortController = new AbortController();

      if (!abortControllerRef.current) {
        abortControllerRef.current = {};
      }
      abortControllerRef.current[requestId] = abortController;

      try {
        const response = await fetch(`${API_URL}/messages`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          body: JSON.stringify({
            content,
            parentId,
            files: files.map((file) => file._id), // 修正
            model,
            chatId: activeChatId,
          }),
          signal: abortController.signal,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const initialMessageData = {
          ...tempMessage, // tempMessage を使用
        };

        const updateStateCallback = (newMessageId, aiResponse) => {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg._id === newMessageId ? { ...msg, aiResponse } : msg
            )
          );
        };

        await processMessageStream(
          response,
          updateStateCallback,
          initialMessageData,
          setMessages,
          setActiveMessageId,
          setActiveMessageIds, // この引数を追加
          tempId, // この引数を追加
          setError,
          () => {
            if (currentRequestIdRef.current === requestId) {
              setIsResponding(false);
              setNotStartingAIResponseYet(false);
            }
          },
          setMessageVersionState // 追加
        );
        setSelectedFiles([]);

        if (!parentId) {
          generateTitle(content).then((generatedTitle) => {
            axios
              .put(`${API_URL}/chats/${activeChatId}/title`, {
                title: generatedTitle,
              })
              .then(() => {
                setCurrentChatTitle(generatedTitle);
                setPastChats((prevChats) =>
                  prevChats.map((chat) =>
                    chat.id === activeChatId
                      ? { ...chat, title: generatedTitle }
                      : chat
                  )
                );
              })
              .catch((error) => {
                console.error("Error updating chat title:", error);
              });
          });
        }

        // 最終更新時刻を更新
        setPastChats((prevChats) => {
          const updatedChats = prevChats.map((chat) =>
            chat.id === activeChatId
              ? { ...chat, lastActivityAt: new Date().toISOString() }
              : chat
          );
          return updatedChats.sort(
            (a, b) => new Date(b.lastActivityAt) - new Date(a.lastActivityAt)
          );
        });
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error sending message:", error);

          // バックエンドからのエラーメッセージを使用
          const errorMessage =
            error.response?.data?.error || "メッセージの送信に失敗しました。";
          setError(errorMessage);
        }
      } finally {
        if (currentRequestIdRef.current === requestId) {
          setNotStartingAIResponseYet(false);
          setIsResponding(false);
          delete abortControllerRef.current[requestId];
        }
      }
    },
    [isResponding, selectedModel, generateTitle, activeChatId, cancelResponse]
  );

  const updateMessage = useCallback(
    async (id, content, files, model) => {
      if (!content.trim() && files.length === 0) return;

      if (files.length > 0 && !modelSupportsFiles(model)) {
        setError("このモデルではファイルを送信できません。");
        return;
      }

      if (isResponding) {
        cancelResponse();
      }

      setIsResponding(true);
      setNotStartingAIResponseYet(true);

      const tempId = Date.now().toString(); // 一意の仮IDを生成

      // 元のメッセージを取得
      const originalMessage = messages.find((m) => m._id === id);
      const messageModel = model || originalMessage.model || selectedModel;

      // 編集したメッセージを新しいメッセージとして作成
      const tempMessage = {
        ...originalMessage,
        _id: tempId, // 新しいIDを使用
        content,
        files, // 修正
        isEdited: true,
        model: messageModel,
        notStartingAIResponseYet: true,
        isResponseComplete: false, // ここでリセット
        createdAt: Date.now(),
        aiResponse: "", // ここで aiResponse をリセット
      };

      // メッセージを追加
      setMessages((prevMessages) => [...prevMessages, tempMessage]);

      // activeMessageId と activeMessageIds を更新
      setActiveMessageId(tempId);
      setActiveMessageIds((prevIds) => [...prevIds.slice(0, -1), tempId]);

      const requestId = Date.now().toString();
      currentRequestIdRef.current = requestId;
      const abortController = new AbortController();

      if (!abortControllerRef.current) {
        abortControllerRef.current = {};
      }
      abortControllerRef.current[requestId] = abortController;

      try {
        const response = await fetch(`${API_URL}/messages/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          body: JSON.stringify({
            content,
            files: files.map((file) => file._id), // 修正
            model: messageModel,
          }),
          signal: abortController.signal,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const updateStateCallback = (newMessageId, aiResponse) => {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg._id === newMessageId ? { ...msg, aiResponse } : msg
            )
          );
        };

        await processMessageStream(
          response,
          updateStateCallback,
          tempMessage,
          setMessages,
          setActiveMessageId,
          setActiveMessageIds,
          tempId,
          setError,
          () => {
            if (currentRequestIdRef.current === requestId) {
              setIsResponding(false);
              setNotStartingAIResponseYet(false);
            }
          },
          setMessageVersionState,
          (oldId, newId) => {
            if (editingId === oldId) {
              setEditingId(newId);
            }
          }
        );

        setPastChats((prevChats) => {
          const updatedChats = prevChats.map((chat) =>
            chat.id === activeChatId
              ? { ...chat, lastActivityAt: new Date().toISOString() }
              : chat
          );
          return updatedChats.sort(
            (a, b) => new Date(b.lastActivityAt) - new Date(a.lastActivityAt)
          );
        });
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error sending message:", error);

          // バックエンドからのエラーメッセージを使用
          const errorMessage =
            error.response?.data?.error || "メッセージの送信に失敗しました。";
          setError(errorMessage);
        }
      } finally {
        if (currentRequestIdRef.current === requestId) {
          setNotStartingAIResponseYet(false);
          setIsResponding(false);
          delete abortControllerRef.current[requestId];
        }
      }
    },
    [
      isResponding,
      selectedModel,
      activeChatId,
      cancelResponse,
      messages,
      editingId,
    ]
  );

  const retryMessage = async (messageId, model) => {
    const message = messages.find((m) => m._id === messageId);
    if (message) {
      await sendMessage(
        message.content,
        message.parentId,
        true,
        message.files, // 修正
        message._id,
        model
      );
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleFileUpload = async (files) => {
    setIsUploading(true); // 追加
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setSelectedFiles((prev) => [...prev, ...response.data.files]);
    } catch (error) {
      console.error("Error uploading files:", error);
      setError(
        "ファイルのアップロードに失敗しました。もう一度お試しください。"
      );
    } finally {
      setIsUploading(false); // 追加
    }
  };

  const removeFile = (index) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (!isResponding && (input.trim() || selectedFiles.length > 0)) {
      // ファイルの検証を追加
      const disallowedModels = [
        "llama-3.1-sonar-huge-128k-online",
        "dall-e-3",
        "stable-image-ultra",
      ];
      const allowedModels = [
        "gemini-1.5-pro",
        "gpt-4o-2024-08-06",
        "claude-3-5-sonnet-20240620",
      ];

      // 音声ファイルの拡張子を定義
      const audioExtensions = [
        "mp3",
        "mp4",
        "mpeg",
        "mpga",
        "m4a",
        "wav",
        "webm",
      ];

      if (selectedFiles.length > 0) {
        if (disallowedModels.includes(selectedModel)) {
          setError("このモデルではファイルを送信できません。");
          return;
        } else if (allowedModels.includes(selectedModel)) {
          // デフォルトの許可された拡張子
          let allowedExtensions = ["png", "jpg", "jpeg", "pdf"];

          // 選択されたモデルが音声ファイルをサポートしている場合、音声拡張子を追加
          if (selectedModel.startsWith("gpt")) {
            allowedExtensions = allowedExtensions.concat(audioExtensions);
          }

          const invalidFiles = selectedFiles.filter((file) => {
            const extension = file.filename.split(".").pop().toLowerCase(); // 修正
            return !allowedExtensions.includes(extension);
          });
          if (invalidFiles.length > 0) {
            setError(
              `許可されていないファイルが含まれています。${allowedExtensions.join(
                "、"
              )}のみ送信可能です。`
            );
            return;
          }
        }
      }
      sendMessage(input, activeMessageId, false, selectedFiles);
      setInput("");
      setSelectedFiles([]);
    }
  };

  const navigateSiblingAtDepth = (depth, direction) => {
    const messageChain = activeMessageIds
      .map((id) => messages.find((m) => m._id === id))
      .filter(Boolean);

    const messageAtDepth = messageChain[depth];
    if (!messageAtDepth) return;

    const siblings = messages
      .filter((m) => m.parentId === messageAtDepth.parentId)
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    const currentIndex = siblings.findIndex(
      (m) => m._id === messageAtDepth._id
    );
    const newIndex = currentIndex + direction;

    if (newIndex >= 0 && newIndex < siblings.length) {
      const newSibling = siblings[newIndex];

      const ancestorChain = messageChain.slice(0, depth);

      const descendantChain = getMessageChainFrom(newSibling);

      const newActiveMessageIds = ancestorChain
        .map((msg) => msg._id)
        .concat(descendantChain.map((msg) => msg._id));

      setActiveMessageIds(newActiveMessageIds);
      setActiveMessageId(newActiveMessageIds[newActiveMessageIds.length - 1]);
    }
  };

  const getMessageChainFrom = useCallback(
    (message) => {
      let chain = [message];
      let currentMessage = message;

      while (true) {
        const currentMessageId = currentMessage._id; // 変数をキャプチャ
        const children = messages
          .filter((m) => m.parentId === currentMessageId)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        if (children.length === 0) break;

        currentMessage = children[0];
        chain.push(currentMessage);
      }

      return chain;
    },
    [messages]
  );

  useEffect(() => {
    if (!activeMessageId) return;

    const getMessageChainFrom = (message) => {
      let chain = [message];
      let currentMessage = message;

      while (true) {
        const currentMessageId = currentMessage._id;
        const children = messages
          .filter((m) => m.parentId === currentMessageId)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        if (children.length === 0) break;

        currentMessage = children[0];
        chain.push(currentMessage);
      }

      return chain;
    };

    const initialMessage = messages.find((m) => m._id === activeMessageId);
    if (!initialMessage) return;

    const descendantChain = getMessageChainFrom(initialMessage);

    let ancestorChain = [];
    let currentMessage = initialMessage;
    while (currentMessage.parentId) {
      const parentId = currentMessage.parentId;
      const parentMessage = messages.find((m) => m._id === parentId);
      if (parentMessage) {
        ancestorChain.unshift(parentMessage);
        currentMessage = parentMessage;
      } else {
        break;
      }
    }

    const fullChain = ancestorChain.concat(descendantChain);

    const ids = fullChain.map((msg) => msg._id);

    setActiveMessageIds(ids);
  }, [activeMessageId, messages]);

  return {
    messages,
    input,
    editingId,
    error,
    isResponding,
    notStartingAIResponseYet,
    activeMessageId,
    selectedFiles,
    pastChats,
    activeChatId,
    currentChatTitle,
    selectedModel,
    isSidebarOpen,
    setIsSidebarOpen,
    isDraggingOver,
    messageVersionState,
    activeMessageIds,
    setEditingId,
    updateMessage,
    retryMessage,
    navigateSiblingAtDepth,
    cancelResponse,
    handleInputChange,
    handleKeyPress,
    handleSendMessage,
    handleFileUpload,
    removeFile,
    setError,
    setSelectedModel,
    startNewChat,
    deleteChat,
    renameChat,
    loadChat,
    setActiveMessageId,
    setMessageVersionState,
    setIsDraggingOver,
    isNewChat, // 追加
    setActiveChatId, // これを追加
    isUploading, // 追加
  };
}

export default useChat;
