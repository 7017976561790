// ChatInterface.js


import React from "react";
import { useOutletContext } from "react-router-dom";
import MessageList from "./MessageList";
import InputArea from "./InputArea";

function ChatInterface() {
  const chatContext = useOutletContext();

  return (
    <div className="flex flex-col h-full">
      {/* メッセージリスト */}
      <MessageList {...chatContext} activeChatId={chatContext.activeChatId} />
      {/* 入力エリア */}
      <InputArea {...chatContext} />
    </div>
  );
}

export default ChatInterface;