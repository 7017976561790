// SideMenu.js
import React, { useState, useEffect, useRef } from "react";
import {
  Bars3Icon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import ChatTree from "../chat/ChatTree";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/outline";

function SideMenu({
  isSidebarOpen,
  setIsSidebarOpen,
  handleLogout,
  email,
  theme,
  setTheme,
  profileImageUrl,
  pastChats,
  activeChatId,
  onChatSelect,
  onChatDelete,
  onChatRename,
  startNewChat,
  messages,
}) {
  // プロフィールメニューの状態管理
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isThemeMenuOpen, setIsThemeMenuOpen] = useState(false);
  const profileMenuRef = useRef(null);
  const profileButtonRef = useRef(null);
  const navigate = useNavigate();

  const handleProfileClick = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
    setIsThemeMenuOpen(false);
  };

  // メニュー外をクリックしたときにメニューを閉じる
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target) &&
        !profileButtonRef.current.contains(event.target)
      ) {
        setIsProfileMenuOpen(false);
        setIsThemeMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getInitial = (email) => {
    return email ? email.charAt(0).toUpperCase() : "";
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  const handleThemeSelect = (selectedTheme) => {
    setTheme(selectedTheme);
    setIsThemeMenuOpen(false);

    // サーバーにテーマを更新
    axios
      .put("/user/profile", { theme: selectedTheme })
      .then((res) => {
        // 必要に応じてレスポンスを処理
      })
      .catch((err) => {
        console.error("Error updating theme in user profile:", err);
      });
  };

  const handleSubscription = () => {
    // サブスクリプションページへのナビゲーション
    console.log("サブスクリプションページへ移動");
  };

  return (
    <div
      className={`fixed top-0 left-0 h-full w-64 bg-sidebarBackground dark:bg-sidebarBackground-dark border-r border-gray-200 dark:border-gray-700 overflow-visible transition-transform duration-300 ${
        isSidebarOpen ? "translate-x-0" : "-translate-x-64"
      }`}
      style={{ zIndex: 1 }}
    >
      <div className="flex flex-col h-full">
        <div className="flex-1 flex flex-col overflow-y-auto">
          {/* サイドバーのヘッダー */}
          <div className="flex items-center justify-between p-2 border-b border-gray-200 dark:border-gray-700">
            <h2 className="text-lg font-bold text-textPrimary dark:text-textPrimary-dark">
              Chat Tree
            </h2>
            <button
              onClick={() => setIsSidebarOpen(false)}
              className="text-textSecondary dark:text-textSecondary-dark hover:text-textPrimary dark:hover:text-textPrimary-dark"
            >
              <Bars3Icon className="h-6 w-6" />
            </button>
          </div>
          {/* サイドバーのコンテンツ */}
          <div className="flex flex-col flex-1">
            {/* New Chat ボタン */}
            <div className="p-2">
              <button
                onClick={startNewChat}
                disabled={
                  pastChats.length > 0 &&
                  activeChatId === pastChats[0].id &&
                  pastChats[0].title === "New Chat" &&
                  messages.length === 0
                }
                className="w-full flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 text-textPrimary dark:text-textPrimary-dark group"
              >
                <ChatBubbleOvalLeftIcon className="h-4 w-4 mr-2" />
                New Chat
              </button>
            </div>
            <div className="flex-grow overflow-y-auto overflow-x-hidden">
              <ChatTree
                pastChats={pastChats.map((chat) => ({
                  ...chat,
                  lastActivityAt: new Date(
                    chat.lastActivityAt
                  ).toLocaleString(),
                }))}
                activeChatId={activeChatId}
                onChatSelect={onChatSelect}
                onChatDelete={onChatDelete}
                onChatRename={onChatRename}
              />
            </div>
          </div>
        </div>
        {/* プロフィールセクション */}
        <div className="relative bg-sidebarBackground dark:bg-sidebarBackground-dark">
          <div
            className="flex items-center cursor-pointer p-2"
            onClick={handleProfileClick}
            ref={profileButtonRef}
          >
            <div className="h-8 w-8 rounded-full overflow-hidden mr-2 bg-gray-200 text-gray-700 flex items-center justify-center">
              {profileImageUrl ? (
                <img
                  src={profileImageUrl}
                  alt="Profile"
                  className="h-full w-full object-cover"
                />
              ) : (
                <span className="font-bold text-lg">{getInitial(email)}</span>
              )}
            </div>
            <span className="text-textPrimary dark:text-textPrimary-dark truncate max-w-[300px]">
              {email}
            </span>
          </div>
          {/* プロフィールメニュー */}
          <div
            ref={profileMenuRef}
            className={`absolute bottom-full left-0 w-48 bg-surface dark:bg-menuBackground-dark border border-gray-200 dark:border-gray-700 rounded shadow-lg z-50 transition-all duration-200 ease-in-out transform ${
              isProfileMenuOpen
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-0 pointer-events-none"
            }`}
          >
            <button
              className="flex items-center justify-between w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 text-textPrimary dark:text-gray-300"
              onClick={handleProfile}
            >
              プロフィール
            </button>
            <button
              className="flex items-center justify-between w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 text-textPrimary dark:text-gray-300"
              onClick={handleSubscription}
            >
              サブスクリプション
            </button>
            <div className="relative">
              <button
                className="flex items-center justify-between w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 text-textPrimary dark:text-gray-300"
                onClick={() => setIsThemeMenuOpen(!isThemeMenuOpen)}
              >
                テーマ
                <ChevronRightIcon className="h-4 w-4" />
              </button>
              {/* テーマメニュー */}
              <div
                className={`absolute top-0 left-full w-40 bg-surface dark:bg-menuBackground-dark border border-gray-200 dark:border-gray-700 rounded shadow-lg z-50 transition-all duration-200 ease-in-out transform ${
                  isThemeMenuOpen
                    ? "opacity-100 translate-x-0"
                    : "opacity-0 translate-x-0 pointer-events-none"
                }`}
              >
                <button
                  className={`block w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 ${
                    theme === "system"
                      ? "font-bold"
                      : "text-textPrimary dark:text-gray-300"
                  }`}
                  onClick={() => handleThemeSelect("system")}
                >
                  システム
                </button>
                <button
                  className={`block w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 ${
                    theme === "light"
                      ? "font-bold"
                      : "text-textPrimary dark:text-gray-300"
                  }`}
                  onClick={() => handleThemeSelect("light")}
                >
                  ライト
                </button>
                <button
                  className={`block w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 ${
                    theme === "dark"
                      ? "font-bold"
                      : "text-textPrimary dark:text-gray-300"
                  }`}
                  onClick={() => handleThemeSelect("dark")}
                >
                  ダーク
                </button>
              </div>
            </div>
            <button
              onClick={handleLogout}
              className="flex items-center justify-between w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 text-error"
            >
              サインアウト
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
