// Profile.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { CameraIcon } from "@heroicons/react/24/outline";

function Profile({ setTheme }) {
  const [userData, setUserData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    phoneNumber: "",
    theme: "system",
    defaultAIModel: "gpt-4",
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);

  const getInitial = (email) => {
    return email ? email.charAt(0).toUpperCase() : "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // テーマの変更を即時反映
    if (name === "theme") {
      setTheme(value);
    }
  };

  const handlePasswordChange = (e) => {
    setPasswordData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setError("新しいパスワードが一致しません。");
      return;
    }
    axios
      .put("/user/password", {
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword,
      })
      .then((res) => {
        setMessage("パスワードが更新されました。");
        setPasswordData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      })
      .catch((err) => {
        console.error("Error updating password:", err);
        setError("パスワードの更新に失敗しました。");
      });
  };

  const handleDeleteAccount = () => {
    if (
      window.confirm(
        "本当にアカウントを削除しますか？この操作は取り消せません。"
      )
    ) {
      axios
        .delete("/user")
        .then((res) => {
          // 必要に応じてログアウト処理を実行
        })
        .catch((err) => {
          console.error("Error deleting account:", err);
          setError("アカウントの削除に失敗しました。");
        });
    }
  };

  useEffect(() => {
    axios
      .get("/user/profile")
      .then((res) => {
        setUserData({
          ...res.data,
          dateOfBirth: res.data.dateOfBirth
            ? res.data.dateOfBirth.substring(0, 10)
            : "",
        });
        if (res.data.profileImageUrl) {
          setPreviewImageUrl(res.data.profileImageUrl);
        }
      })
      .catch((err) => {
        console.error("Error fetching profile:", err);
      });
  }, []);

  const handleProfileSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in userData) {
      formData.append(key, userData[key]);
    }
    if (profileImageFile) {
      formData.append("profileImage", profileImageFile);
    }

    axios
      .put("/user/profile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setMessage("プロフィールが更新されました。");
        if (res.data.profileImageUrl) {
          setPreviewImageUrl(res.data.profileImageUrl);
        }
      })
      .catch((err) => {
        console.error("Error updating profile:", err);
        setError("プロフィールの更新に失敗しました。");
      });
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImageFile(file);
      const previewUrl = URL.createObjectURL(file);
      setPreviewImageUrl(previewUrl);
    }
  };

  return (
    <div className="p-4 max-w-3xl mx-auto flex-grow overflow-auto">
      {message && (
        <p className="text-green-500 mb-4 text-center font-semibold">
          {message}
        </p>
      )}
      {error && (
        <p className="text-red-500 mb-4 text-center font-semibold">{error}</p>
      )}

      <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8 mb-8">
        <h2 className="text-2xl font-bold mb-6 text-center">
          プロフィール設定
        </h2>
        <form onSubmit={handleProfileSubmit}>
          {/* プロフィール画像 */}
          <div className="flex flex-col items-center mb-8">
            <div className="relative">
              <div className="h-32 w-32 rounded-full overflow-hidden bg-gray-200 dark:bg-gray-700">
                {previewImageUrl ? (
                  <img
                    src={previewImageUrl}
                    alt="Preview"
                    className="h-full w-full object-cover"
                  />
                ) : (
                  <span className="font-bold text-4xl flex items-center justify-center h-full text-gray-700 dark:text-gray-300">
                    {getInitial(userData.email)}
                  </span>
                )}
              </div>
              <label
                htmlFor="profileImage"
                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity cursor-pointer rounded-full"
              >
                <CameraIcon className="h-8 w-8 text-white" />
                <input
                  type="file"
                  id="profileImage"
                  name="profileImage"
                  accept="image/*"
                  onChange={handleProfileImageChange}
                  className="hidden"
                />
              </label>
            </div>
            <p className="text-gray-600 dark:text-gray-300 mt-4">
              プロフィール画像を変更
            </p>
          </div>

          {/* 名前フィールド */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-gray-700 dark:text-gray-300 mb-2">
                姓
              </label>
              <input
                type="text"
                name="lastName"
                value={userData.lastName || ""}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <div>
              <label className="block text-gray-700 dark:text-gray-300 mb-2">
                名
              </label>
              <input
                type="text"
                name="firstName"
                value={userData.firstName || ""}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
          </div>

          {/* その他のフィールド */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-gray-700 dark:text-gray-300 mb-2">
                生年月日
              </label>
              <input
                type="date"
                name="dateOfBirth"
                value={userData.dateOfBirth || ""}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <div>
              <label className="block text-gray-700 dark:text-gray-300 mb-2">
                電話番号
              </label>
              <input
                type="tel"
                name="phoneNumber"
                value={userData.phoneNumber || ""}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
          </div>

          {/* メールアドレス */}
          <div className="mb-6">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              メールアドレス
            </label>
            <input
              type="email"
              name="email"
              value={userData.email || ""}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>

          {/* テーマとAIモデル */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-gray-700 dark:text-gray-300 mb-2">
                テーマ
              </label>
              <select
                name="theme"
                value={userData.theme || "system"}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              >
                <option value="system">システム</option>
                <option value="light">ライト</option>
                <option value="dark">ダーク</option>
              </select>
            </div>
            <div>
              <label className="block text-gray-700 dark:text-gray-300 mb-2">
                デフォルトAIモデル
              </label>
              <select
                name="defaultAIModel"
                value={userData.defaultAIModel || "gpt-4"}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              >
                <option value="gpt-4">GPT-4</option>
                <option value="claude">Claude</option>
                {/* 他のモデルも追加 */}
              </select>
            </div>
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors duration-200"
            >
              プロフィールを更新
            </button>
          </div>
        </form>
      </div>

      {/* パスワード変更 */}
      <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8 mb-8">
        <h2 className="text-2xl font-bold mb-6 text-center">
          パスワードを変更
        </h2>
        <form onSubmit={handlePasswordSubmit}>
          <div className="mb-6">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              現在のパスワード
            </label>
            <input
              type="password"
              name="currentPassword"
              value={passwordData.currentPassword}
              onChange={handlePasswordChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              新しいパスワード
            </label>
            <input
              type="password"
              name="newPassword"
              value={passwordData.newPassword}
              onChange={handlePasswordChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div className="mb-8">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              新しいパスワード（確認）
            </label>
            <input
              type="password"
              name="confirmPassword"
              value={passwordData.confirmPassword}
              onChange={handlePasswordChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors duration-200"
            >
              パスワードを変更
            </button>
          </div>
        </form>
      </div>

      {/* アカウント削除 */}
      <div className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8">
        <h2 className="text-2xl font-bold mb-4 text-center text-red-600">
          アカウントの削除
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-6 text-center">
          アカウントを削除すると、すべてのデータが失われます。この操作は取り消せません。
        </p>
        <div className="flex justify-center">
          <button
            onClick={handleDeleteAccount}
            className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-200"
          >
            アカウントを削除
          </button>
        </div>
      </div>
    </div>
  );
}

export default Profile;
