import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

function Login({ setAuthToken }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // エラーメッセージと情報メッセージを管理
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleSocialLogin = (provider) => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/${provider}`;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setMessage("");

      // サーバーにログインリクエストを送信
      const response = await axios.post("/login", {
        email,
        password,
      });

      // トークンを取得して保存
      const token = response.data.token;
      localStorage.setItem("authToken", token);
      setAuthToken(token);

      // axiosのデフォルトヘッダーにトークンを設定
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      // チャットページにリダイレクト
      navigate("/chat");
    } catch (error) {
      console.error("Error logging in:", error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.error === "メールアドレスが確認されていません。"
      ) {
        setMessage(
          "メールアドレスが確認されていません。認証コードを入力してください。"
        );

        // 認証コード入力ページにリダイレクト
        setTimeout(() => {
          navigate("/verify-email", { state: { email } });
        }, 2000);
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        setError(error.response.data.error);
      } else {
        setError("ログインに失敗しました。もう一度お試しください。");
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800 dark:text-white">
          ログイン
        </h2>
        {message && (
          <p className="text-green-500 mb-4 text-center font-semibold">
            {message}
          </p>
        )}
        {error && (
          <p className="text-red-500 mb-4 text-center font-semibold">{error}</p>
        )}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              メールアドレス
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white"
              placeholder="メールアドレスを入力"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              パスワード
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white"
              placeholder="パスワードを入力"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary-dark transition-colors duration-200"
          >
            ログイン
          </button>
          {/* ソーシャルログインボタン */}
          <button
            type="button"
            onClick={() => handleSocialLogin("google")}
            className="w-full bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition-colors duration-200 mt-4"
          >
            Googleでログイン
          </button>
          <button
            type="button"
            onClick={() => handleSocialLogin("microsoft")}
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-200 mt-2"
          >
            Microsoftでログイン
          </button>
        </form>
        <p className="mt-6 text-center text-gray-600 dark:text-gray-400">
          アカウントをお持ちでないですか？{" "}
          <Link to="/register" className="text-primary hover:underline">
            新規登録はこちら
          </Link>
        </p>
        <p className="mt-4 text-center text-gray-600 dark:text-gray-400">
          <Link to="/forgot-password" className="text-primary hover:underline">
            パスワードをお忘れですか？
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
