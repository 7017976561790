// MessageList.js

import React, { memo, useRef, useEffect} from "react";
import MessageBubble from "./MessageBubble";
import isEqual from "lodash.isequal";

const MessageList = memo(
  ({
    messages,
    activeMessageIds,
    messageVersionState,
    setMessageVersionState,
    editingId,
    setEditingId,
    updateMessage,
    isResponding,
    setError,
    retryMessage,
    setActiveMessageId,
    navigateSiblingAtDepth,
    selectedModel,
    setSelectedModel,
    activeChatId, // 追加
  }) => {
    // 参照の定義
    const messagesContainerRef = useRef(null);
    const messagesEndRef = useRef(null);

    // スクロール位置の保存
    useEffect(() => {
      if (activeChatId && messagesContainerRef.current) {
        const element = messagesContainerRef.current; // ローカル変数にコピー
        const saveScrollPosition = () => {
          const scrollTop = element.scrollTop;
          localStorage.setItem(`scrollPosition_${activeChatId}`, scrollTop);
        };

        element.addEventListener("scroll", saveScrollPosition);

        return () => {
          element.removeEventListener("scroll", saveScrollPosition);
        };
      }
    }, [activeChatId]);

    // スクロール位置の復元
    useEffect(() => {
      if (activeChatId && messagesContainerRef.current) {
        const savedScrollPosition = localStorage.getItem(
          `scrollPosition_${activeChatId}`
        );
        if (savedScrollPosition !== null) {
          const restoreScroll = () => {
            messagesContainerRef.current.scrollTop = savedScrollPosition;
          };
          // 次の描画フレームまで待機
          requestAnimationFrame(restoreScroll);
        }
      }
    }, [activeChatId, messages]);

    const renderMessages = () => {
      const messageChain = activeMessageIds
        .map((id) => messages.find((m) => m._id === id))
        .filter(Boolean);

      return messageChain.map((message, index) => (
        <MessageBubble
          key={message._id}
          messageId={message._id}
          messages={messages}
          messageVersionState={messageVersionState}
          setMessageVersionState={setMessageVersionState}
          editingId={editingId}
          setEditingId={setEditingId}
          updateMessage={updateMessage}
          isResponding={isResponding}
          setError={setError}
          retryMessage={retryMessage}
          setActiveMessageId={setActiveMessageId}
          depth={index}
          navigateSiblingAtDepth={navigateSiblingAtDepth}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          messagesContainerRef={messagesContainerRef} // 必要なら渡す
        />
      ));
    };

    return (
      <div
        className="flex-grow overflow-auto p-2 bg-background dark:bg-background-dark text-textPrimary dark:text-white w-full min-w-0"
        ref={messagesContainerRef}
      >
        <div className="w-full max-w-full md:max-w-2xl mx-auto overflow-x-auto">
          {renderMessages()}
          <div ref={messagesEndRef} />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    // memoization logic
    return (
      isEqual(prevProps.activeMessageIds, nextProps.activeMessageIds) &&
      isEqual(prevProps.messages, nextProps.messages) &&
      prevProps.isResponding === nextProps.isResponding &&
      prevProps.editingId === nextProps.editingId &&
      isEqual(prevProps.messageVersionState, nextProps.messageVersionState)
    );
  }
);

export default MessageList;
