// Header.js
import React from "react";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";

const Header = ({
  currentChatTitle,
  setIsSidebarOpen,
  isSidebarOpen,
  handleLogout,
  email,
}) => {
  const location = useLocation();

  // パスに応じてタイトルを設定
  const title =
    location.pathname === "/profile" ? "" : currentChatTitle;

  return (
    <div className="relative flex items-center bg-background dark:bg-background-dark text-textPrimary dark:text-textPrimary-dark p-2">
      {/* 左側のエリア */}
      <div className="flex items-center w-1/3">
        {setIsSidebarOpen && !isSidebarOpen && (
          <button
            onClick={() => setIsSidebarOpen(true)}
            className="text-textSecondary dark:text-textSecondary-dark hover:text-textPrimary dark:hover:text-textPrimary-dark"
          >
            <Bars3Icon className="h-6 w-6" />
          </button>
        )}
      </div>
      {/* 中央のタイトル */}
      <div className="w-1/3 flex justify-center">
        <h1 className="text-lg font-bold whitespace-nowrap overflow-ellipsis max-w-full">
          {title}
        </h1>
      </div>
    </div>
  );
};

export default Header;
